const data = [
  {
    id: 1,
    image:
      "https://newsroompost.com/wp-content/uploads/2020/10/buying-a-home.jpg",
    name: "Buy a home",
  },
  {
    id: 2,
    image:
      "https://www.investopedia.com/thmb/lUMUQyheogwYhtdQ_p8aBDNG9fg=/680x440/filters:fill(auto,1)/rent_house_73089751-5bfc333346e0fb002602ddbe.jpg",
    name: "Rent a home",
  },
  {
    id: 3,
    image: "https://static.99acres.com/universalapp/img/d_hp_ppf_xl.png",
    name: "Sell/Rent your property",
  },
  {
    id: 4,
    image:
      "https://www.colive.com/blog/wp-content/uploads/2019/04/fully-furnished-coliving-space-vs-PG-in-Bangalore.jpg",
    name: "PG and co-living",
  },
  {
    id: 5,
    image:
      "https://www.kohinoorpune.com/hs-fs/hubfs/Buy%20commercial%20real%20property%20in%20Pune.jpg?width=600&name=Buy%20commercial%20real%20property%20in%20Pune.jpg",
    name: "Buy commercial spaces",
  },
  {
    id: 6,
    image:
      "https://blog.ipleaders.in/wp-content/uploads/2013/09/Commercial-Space-For-Lease.jpg",
    name: "Lease commercial spaces",
  },
];
export default data;
