import React from "react";

const Warning = () => {
  return (
    <div className="Cities_main_container_div ">
      <div className="WarningContainer_div">
        <p>
          DISCLAIMER: Currently many scams are going on in the market and real
          estate too is not spared. Homescouts hereby wants to notify all it's
          valuable users to not make any payments without due diligence. We at
          HomeScouts, care for the financial safety of our users, Kindly beware
          of scammers. Do not disclose your financial details or transfer
          payment to any service provider / owner etc without proper background
          check.
        </p>
      </div>
    </div>
  );
};

export default Warning;
