import imgs1 from "../../../assets/services/Ellipse 31 (1).png";
import imgs2 from "../../../assets/services/Ellipse 31.png";
import img1 from "../../../assets/statusimg/img1.jpg";
import img2 from "../../../assets/statusimg/img2.jpg";
import img3 from "../../../assets/statusimg/img3.jpg";
import img4 from "../../../assets/statusimg/img4.jpg";
import img5 from "../../../assets/statusimg/img5.jpg";
import img6 from "../../../assets/statusimg/img6.jpg";
import img7 from "../../../assets/statusimg/img7.jpg";
import img8 from "../../../assets/statusimg/img8.jpg";
import img9 from "../../../assets/statusimg/img9.jpg";
import img10 from "../../../assets/statusimg/img10.jpg";
import img11 from "../../../assets/statusimg/img11.jpg";
import img12 from "../../../assets/statusimg/img12.jpg";
import img13 from "../../../assets/statusimg/img13.jpg";

const data = {
  statusData: [
    {
      id: 1,
      img: imgs1,
      imgstatus: img1,
      storyData: [
        {
          type: "image",
          url: img1,
          duration: 3000,
        },
        {
          type: "image",
          url: img2,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img7,
        },
      ],
    },
    {
      id: 2,
      img: imgs2,
      imgstatus: img2,
      storyData: [
        {
          type: "image",
          url: img2,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img7,
        },
        {
          duration: 2000,
          type: "image",
          url: img3,
        },
        {
          type: "image",
          duration: 2000,
          url: img7,
        },
        {
          duration: 2000,
          type: "image",
          url: img3,
        },
      ],
    },
    {
      id: 3,
      img: imgs1,
      imgstatus: img3,
      storyData: [
        {
          type: "image",
          url: img3,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img4,
        },
      ],
    },
    {
      id: 4,
      img: imgs2,
      imgstatus: img4,
      storyData: [
        {
          type: "image",
          url: img5,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img9,
        },
        {
          duration: 2000,
          type: "image",
          url: img10,
        },
        {
          duration: 2000,
          type: "image",
          url: img10,
        },
      ],
    },
    {
      id: 5,
      img: imgs1,
      imgstatus: img5,
      storyData: [
        {
          type: "image",
          url: img13,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img11,
        },
        {
          duration: 2000,
          type: "image",
          url: img12,
        },
        {
          type: "image",
          url: img13,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img11,
        },
        {
          duration: 2000,
          type: "image",
          url: img12,
        },
      ],
    },
    {
      id: 6,
      img: imgs2,
      imgstatus: img6,
      storyData: [
        {
          type: "image",
          url: img1,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img10,
        },
        {
          duration: 2000,
          type: "image",
          url: img5,
        },
      ],
    },
    {
      id: 7,
      img: imgs1,
      imgstatus: img7,
      storyData: [
        {
          type: "image",
          url: img3,
          duration: 3000,
        },
      ],
    },
    {
      id: 8,
      img: imgs2,
      imgstatus: img8,
      storyData: [
        {
          type: "image",
          url: img4,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img7,
        },
        {
          duration: 2000,
          type: "image",
          url: img11,
        },
      ],
    },
    {
      id: 9,
      img: imgs1,
      imgstatus: img9,
      storyData: [
        {
          type: "image",
          url: img13,
          duration: 3000,
        },
        {
          type: "image",
          duration: 2000,
          url: img5,
        },
        {
          duration: 2000,
          type: "image",
          url: img1,
        },
      ],
    },
    // {
    //     id:10,
    //     img:imgs2,
    //     imgstatus:img10,
    // },
    // {
    //     id:11,
    //     img:imgs1,
    //     imgstatus:img11,
    // },
    // {
    //     id:12,
    //     img:imgs2,
    //     imgstatus:img12,
    // },
    // {
    //     id:13,
    //     img:imgs2,
    //     imgstatus:img13,
    // },
  ],
};
export default data;
